const People = () => {
    return (
        <div className="d-flex-bteween mb-2">
            <div className="mt-3 d-flex   align-items-center text-black-50">
                <img src={require("./myPhoto.PNG")} alt="my image" className="img-sm circle-element" style={{ marginLeft: "-5px", border: "1px solid var(--blue)", zIndex: 11, position: "relative" }} />
                <img src={require("./myPhoto.PNG")} alt="my image" className="img-sm circle-element" style={{ marginLeft: "-5px", border: "1px solid var(--blue)", zIndex: 10, position: "relative" }} />
                <img src={require("./myPhoto.PNG")} alt="my image" className="img-sm circle-element" style={{ marginLeft: "-5px", border: "1px solid var(--blue)", zIndex: 9, position: "relative" }} />
                <span className="ms-2 fw-bold" style={{ fontSize: "11px" }}> + 15 People</span>
            </div>
            <span className="main-cricle d-flex-center"><i className="fa-solid fa-plus" style={{ fontSize: "10px" }}></i></span>
        </div>
    )
}

export default People;