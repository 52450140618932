import { useDispatch, useSelector } from "react-redux";
import { hideCard } from "../../store/slice/toggleHideSlice";
import { hideActiveBox } from "../../utilitis/utilitis";
import { useEffect } from "react";

const HideCard = ({ cardState }) => {
    const dispatch = useDispatch();
    const state = useSelector(store => store.toggleHideSlice);
    useEffect(() => {
        hideActiveBox(state);
    }, [state]);
    return (
        <span className="main-cricle d-flex-center close-btn" onClick={() => {
            dispatch(hideCard(cardState));
        }}>
            <i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i>
        </span>
    )
}

export default HideCard;