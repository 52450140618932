import CardToggleInput from "../CardToggleInput/CardToggleInput";

const SidebarLabels = () => {
    return (
        <div className="d-flex-bteween mb-3 pb-3" style={{ borderBottom: "1px solid var(--vivid-cerulean)" }}>
            <div>
                <h4 className="mt-3">Sidebar</h4>
                <p className="text-black-50 fw-bold" style={{ fontSize: "13px" }}>You can controal your sidebard cards as you want</p>
            </div>
            <div>
                <CardToggleInput cardState="chatBootState" labelText="Chat Boot" />
                <CardToggleInput cardState="newCardState" labelText="New Card" />
                <CardToggleInput cardState="labelsState" labelText="Labels" />
            </div>
        </div>
    )
}

export default SidebarLabels;