import CheckBox from "../../Sidebar/Labels/CheckBox";

const HomeTask = ({task , index}) => {
    return (
        <div key={index} className="d-flex align-items-center mb-3" style={{ borderBottom: "1px solid #0a9ff821" }}>
            <CheckBox index={index + 3} radius={true} />
            <div className="ms-2 d-flex-bteween w-100">
                <h6 style={{ fontSize: "14px" }}>
                    <div>{task.taskName}</div>
                    <div className="text-black-50" style={{ fontSize: "11px" }}>{task.date}</div>
                </h6>
                <img src={require("./myPhoto.PNG")} alt="my Photo" className="img-sm circle-element" />
            </div>
        </div>
    )
}

export default HomeTask;