
import { useEffect } from "react";
import Header from "../Header/Header";
import { toggleFullScreenImage } from "../../utilitis/utilitis";
import Skills from "./Skills";
import AboutContent from "./AboutContent";
import SocialAbout from "./SocialAbout";
const About = () => {
    useEffect(() => {
        toggleFullScreenImage();
    }, []);
    return (
        <div>
            <div className="mb-2">
                <Header />
            </div>
            <div className="d-flex-center flex-column " style={{ minHeight: "90vh" }}>
                <div className="row container p-3" style={{ "background": "var(--vivid-cerulean)" }}>
                    <nav className="col-lg-3" style={{ "background": "var(--vivid-cerulean)" }}>
                        <div className="position-relative ps-2 pe-2" style={{ "height": "200px", "width": "100%", "marginBottom": "20px" }}>
                            <div className=" d-flex justify-content-center align-items-center flex-column w-100 h-100" style={{ "background": "var(--vivid-cerulean)" }}>
                                <img src={require("./myPhoto.PNG")} style={{ maxWidth: '100%', width: "150px", height: "150px", border: "2px solid white" }} className="circle-element" alt="my-photo" width="200px" height="180px" />
                                <p className="text-white mt-3 mb-4">Front-End
                                    Developer</p>
                            </div>
                        </div>
                        <Skills />
                        <SocialAbout />
                    </nav>
                    <AboutContent />
                </div>
            </div>
        </div>
    )
}

export default About;