const Task = ({user , index}) => {
    return (
        <div key={index} className={`task ${user.class && "active"}  p-2 ps-3 pe-3 d-flex-bteween`}>
            <div>
                <img src={require("./myPhoto.PNG")} alt="my Photo" className="img-sm circle-element me-2" />
                <span className="fw-bold text-black-50" style={{ fontSize: "12px" }}>{user.name}</span>
            </div>
            <p className="fw-bold text-black-50 m-0" style={{ fontSize: "9px" }}>{user.tasksCount} Task On Progress</p>
        </div>
    )
}

export default Task;