import CardToggleInput from "../CardToggleInput/CardToggleInput";

const HomePageLabels = () => {
    return (
        <div className="d-flex-bteween mb-3 pb-3" style={{ borderBottom: "1px solid var(--vivid-cerulean)" }}>
            <div>
                <h4 className="mt-3">Home Page</h4>
                <p className="text-black-50 fw-bold" style={{ fontSize: "13px" }}>You can controal your Home Page cards as you want</p>
            </div>
            <div>
                <CardToggleInput cardState="projectProgressState" labelText="Project Progress" />
                <CardToggleInput cardState="fileAttachemntState" labelText="File Attachemnt" />
                <CardToggleInput cardState="assignTaskState" labelText="Assign Task" />
            </div>
        </div>
    )
}

export default HomePageLabels;