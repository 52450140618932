const Skills = () => {
    return (
        <div className="ps-3 pe-3">
            <h1 style={{ "fontSize": "20px" }} className=" text-white fw-bold
        text-center">Skills &amp; Knowloedge</h1>
            <div className="d-lg-block d-flex justify-content-center align-items-center flex-wrap mb-3" style={{ "borderBottom": "3px solid white" }}>
                <p className="text-white pe-3 fw-bold " style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2 fw-bold" style={{ "marginRight": "10px", "fontSize": "11px" }}></i>
                    JavaScript</p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> React , Reducx , Redux ToolKit</p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> Algorithm , Data Structures
                </p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> Git, Github
                </p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> Problem Solving
                </p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> THREE.JS</p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> BootStrap</p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> Canvas</p>
                <p className="text-white pe-3 fw-bold" style={{ "fontSize": "12px" }}><i className="fa-solid fa-check me-2" style={{ "fontSize": "11px" }}></i> HTML , CSS</p>
            </div>
        </div>
    )
}

export default Skills;