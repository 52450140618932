import { useState } from "react";
import { handelSearch } from "../../../../utilitis/utilitis";
import Task from "./Task";

const Tasks = () => {
    const [userValue, setUserValue] = useState("");
    let [userTasks, setUserTasks] = useState([
        { name: "Mahmoud Nabowy", tasksCount: 15, class: false },
        { name: "Angle Smith", tasksCount: 25, class: true },
        { name: "Karen Wiliam", tasksCount: 35, class: false },
        { name: "Andy Hope", tasksCount: 12, class: false },
        { name: "Angle Smith", tasksCount: 25, class: false },
    ]);
    const renderTasks = userTasks.map((user, index) => {
        return <Task user={user} index={index} />
    });

    return (
        <div className="bg-white mb-2" style={{ borderRadius: "var(--radius)" }}>
            <div className="p-3">
                <form className="position-relative mb-3" onSubmit={e => {
                    e.preventDefault();
                    handelSearch(setUserTasks, userTasks, userValue);
                }}>
                    <input type="search" className="min-input w-100" onChange={e => setUserValue(e.target.value)} placeholder="Enter to search by name" id="search-task" />
                    <i className="fa-solid fa-magnifying-glass search-task-icone"></i>
                </form>
            </div>
            {renderTasks}
        </div>
    )
}

export default Tasks;