import { useDispatch, useSelector } from "react-redux";
import { hideCard, showCard } from "../../../store/slice/toggleHideSlice";
const CardToggleInput = ({ cardState, labelText }) => {
    const dispatch = useDispatch();
    const useToggleHideStore = useSelector(store => store.toggleHideSlice);

    const getActiveCardState = (card) => useToggleHideStore.filter(cardState => cardState[card]);
    const isCardActive = (card) => getActiveCardState(card).length ? true : false;

    // Function to handle active card state
    const toggleCardState = (card, target) => {
        // Check if the card is not already active
        if (!isCardActive(card)) {
            // Check if the target element does not have the 'checked' class
            if (!target.classList.contains("checked")) {
                // If not, add the 'checked' class to the target element
                target.classList.add("checked");
            }
            // Dispatch an action to show the card with a new state
            dispatch(showCard(card));
        }
        // If the card is already active
        else {
            // Check if the target element has the 'checked' class
            if (target.classList.contains("checked")) {
                // If yes, remove the 'checked' class from the target element
                target.classList.remove("checked");
            }
            // Dispatch an action to hide the card with a new state
            dispatch(hideCard(card));
        }
    }
    return (
        <label className="d-flex align-items-center mb-2" style={{ minWidth: "235px" }}>
            <input className="toggle-checkbox" onChange={(e) => toggleCardState(cardState, e.target)} defaultChecked={isCardActive(cardState)} type="checkbox" />
            <div className="toggle-switch"></div>
            <p className="m-0 fw-bold ms-2">{labelText}</p>
        </label>
    )
}

export default CardToggleInput;