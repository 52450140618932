export const hideActiveBox = (state) => {
    // loop through all close buttons
    state.forEach(boxState => {
        const getBoxId = Object.keys(boxState)[0];
        const currentBox = document.getElementById(getBoxId);
        if (currentBox) {
            currentBox.style.cssText += "transition: var(--transition)";
            // Check if the state of the current box matches the provided state as 'true'
            if (boxState[getBoxId] === true) {
                // If yes, remove the 'hidden' class from the element to show the box
                currentBox.classList.remove("hidden");
            }
            // Check if the state of the current box matches the provided state as 'false'
            else if (boxState[getBoxId] === false) {
                // If yes, check if the box is not already hidden
                if (!currentBox.classList.contains("hidden")) {
                    // If not, add the 'hidden' class to the element to hide the box
                    currentBox.classList.add("hidden");
                }
            }
        }
    });
}

// This is the search logic function that takes an event object as input
export const searchLogic = (e, userValue) => {
    // initlizae array of words for each box
    const box1 = [
        "start desing homepage", "home", "home page", "design", "project", "file", "attachemnt", "file Attachemnt", "task", "assign"
    ];
    const box2 = [
        "weekly progress", "progress", "weekly", "loading", "button", "activity", "recent"
    ];
    const box3 = [
        "tasks", "task progress", "copywriting", "illustrations", "ui Design"
    ];
    // Prevents the default form submission behavior
    e.preventDefault();
    // Converts the user input to lowercase
    const value = userValue.toLowerCase();

    // Function to remove border from specified elements
    const removeBorder = (...elements) => {
        elements.forEach(ele => ele.style.border = "");
    }

    // Function to add border to a specified element
    const addBorder = element => element.style.border = "3px dashed var(--blue)";

    // Selects the first, second and third boxes in the DOM
    const FIRST_BOX = document.querySelector(".first-box");
    const SECOND_BOX = document.querySelector(".second-box");
    const THIRED_BOX = document.querySelector(".thired-box");

    // Removes border from all boxes
    removeBorder(FIRST_BOX, SECOND_BOX, THIRED_BOX)

    // Function to check if a box contains the search value and add border if true
    const getCurrentBox = (box, add, ...remove) => {
        if (box.includes(value)) {
            addBorder(add);
            removeBorder(...remove);
        }
    }

    // Checks if the search value is in box1 and updates borders accordingly
    getCurrentBox(box1, FIRST_BOX, SECOND_BOX, THIRED_BOX);

    // Checks if the search value is in box2 and updates borders accordingly
    getCurrentBox(box2, SECOND_BOX, FIRST_BOX, THIRED_BOX);

    // Checks if the search value is in box3 and updates borders accordingly
    getCurrentBox(box3, THIRED_BOX, FIRST_BOX, SECOND_BOX);
}

// ChatBoot 
export const chatLogic = () => {
    const chatWrapper = document.querySelector(".chat-wrapper");
    const chatInput = document.querySelector(".user-chat input");
    const messages = [
        "Thanks for reaching out to Nabowy Dashboard! Our business hours are M-F, 9 AM-5 PM PT. We’ll get back to you within one business day.",
        "Thanks for contacting Jill at Nabowy Dashboard. I’m currently out of the office. I’ll be back at 1 PM and will respond to your message shortly.",
        "I’m currently camping in a WiFi-free zone! I’ll be back on May 10 to respond to your message. For urgent matters, please reach out to Joe at 01125480514.",
        "Our team is out spending time with loved ones. We’ll be back on November 29 to respond to your text. Have a great Thanksgiving weekend!",
        "We’ve got your back! A member of our support team will respond to your message within 2-3 hours. Thank you for your patience.",
        "Hello! We’re currently getting more texts than usual. We’ve received your message and will respond as soon as we can. Thank you for your patience.",
        "Thanks for your message! An expert will get back to you very soon. In the meantime, our help center may be able to answer your questions faster",
        "We’ve received your report. We’ll reach out if we need more information and let you know once the bug is resolved. Thanks for helping our site perform better!",
        "Sorry we missed your call! A member of our team will get back to you by the end of the day.",
        "Thanks for leaving a voicemail! We appreciate your patience and will get back to you by the end of the day.",
        "Thanks for contacting Nabowy Dashboard! One of our sales reps will reach out to you shortly. Tap to browse our summer sale while you wait: nabowy.online",
        "Thanks for contacting Nabowy Dashboard! We’ll reach out within an hour. Have you read our latest eBook? Fill out this form to get your free copy",
        "Thanks for reaching out! This inbox is not monitored. Please call this number or email us at [enter your support email address] so we can support your needs.",
        "Welcome to Nabowy Dashboard SMS Rewards! Keep your eyes peeled for your first exclusive discount, coming to you soon.",
        "Thanks for opting into text alerts for your recent order. We’ll send you a message once your order has shipped!",
        "Sorry we missed your call. If you’re looking to book an appointment you can schedule online here: nabowy.online.",
        "Thanks for your first Nabowy Dashboard purchase! We’re excited for you to try out our product. Here’s how you can set it up: nabowy.work@gmail.com",
        "Thanks for entering the Nabowy Dashboard spring sweepstakes! We’ll reach out to the winners on August 31 by noon PST."
    ];
    let autoMessageTimeout;
    // Create a function to draw the UI for a message
    const renderMessageBoot = (message, time, sender) => {
        const messageContainer = document.createElement("div");
        // classList.add it's can acsspet more than one class
        messageContainer.classList.add("d-flex-bteween", "p-1", "mb-2");
        messageContainer.style.cssText = "background: #6097ff38; border-radius: 5px; width: fit-content; max-width: 90%;";

        const messageText = document.createElement("p");
        messageText.style.cssText = "font-weight: 400; font-size: 12px; width: 85%; word-break: break-all;";
        messageText.innerHTML = message;

        const messageTime = document.createElement("span");
        messageTime.style.cssText = "width: fit-content; font-size: 11px; align-self: flex-end;";
        messageTime.classList.add("text-black-50", "fw-bold", "me-2");
        messageTime.innerHTML = time;

        if (sender === "user") {
            messageContainer.style.alignSelf = "flex-end";
            messageContainer.style.background = "#315bb947";
        } else {
            messageText.style.opacity = "0.7";
            messageTime.style.opacity = "0.7";
        }
        // loop for messages to append it to container
        [messageText, messageTime].forEach(message => messageContainer.appendChild(message));
        chatWrapper.appendChild(messageContainer);
    };
    // Create a function to send a message from the bot
    const sendAutoMessage = () => {
        const messageIndex = Math.floor(Math.random() * messages.length);
        const message = messages[messageIndex];
        const date = new Date();
        const time = `${date.getHours()}:${date.getMinutes()}`;

        renderMessageBoot(message, time, "boot");
    };
    // Create an event listener for the chat input
    chatInput.addEventListener("keyup", (event) => {
        if (event.key === "Enter") {
            const message = event.target.value;
            const date = new Date();
            const time = `${date.getHours()}:${date.getMinutes()}`;

            if (message != "") {
                renderMessageBoot(message, time, "user");
                event.target.value = "";

                // Clear the previous timeout and set a new one
                clearTimeout(autoMessageTimeout);
                autoMessageTimeout = setTimeout(() => {
                    sendAutoMessage();
                }, 2000);
            }
        }
    });
};

export const toggleFullScreenImage = () => {
    const images = document.querySelectorAll("img");
    images.forEach(img => {
        img.addEventListener("click", () => {
            img.style.cssText += "border-radius: 0 !important;";
            img.requestFullscreen();
            console.log("Nabowy")
        });
        img.addEventListener("fullscreenchange", () => {
            if (!document.fullscreenElement) {
                img.style.cssText += "border-radius: 50% !important;";
            }
        })
    });
}
export const handelSearch = (setUserTasks, userTasks, userValue) => {
    // loop for tasks to search for user value
    const newTasks = userTasks.map(task => {
        const nameToLowercase = task.name.toLowerCase();
        const userValueToLowercase = userValue.toLowerCase();
        if (userValue == "") {
            task.class = false;
            return task;
        }
        if (nameToLowercase == userValueToLowercase || nameToLowercase.includes(userValueToLowercase)) {
            task.class = true;
        } else {
            task.class = false;
        }

        return task;
    });
    return setUserTasks(newTasks);
}

export const matchBoxHeights = () => {
    const boxOne = document.querySelector(".first-box");
    const boxTwo = document.querySelector(".second-box");
    const boxThree = document.querySelector(".thired-box");
    const getBiggestBoxHeight = Math.max(boxOne.clientHeight, boxTwo.clientHeight, boxThree.clientHeight);
    const addMinHeightToBoxs = (...elements) => {
        elements.forEach(ele => ele.style.minHeight = getBiggestBoxHeight + "px");
    };
    addMinHeightToBoxs(boxOne, boxTwo, boxThree);
};