import { configureStore } from "@reduxjs/toolkit";
import newCardSlice from "./slice/newCardSlice";
import toggleHideSlice from "./slice/toggleHideSlice";

const store = configureStore({
    reducer: {
        newCard: newCardSlice,
        toggleHideSlice: toggleHideSlice
    }
});

export default store;