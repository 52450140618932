import { useSelector } from "react-redux";
import DeleteTask from "./DeleteTask/DeleteTask";

const Tasks = () => {
    const arrayOfTasks = useSelector(store => store.newCard);
    const renderTasks = arrayOfTasks.map((task, index) => {
        return (
            <div className="mb-2 p-3 bg-white" key={index} style={{ borderRadius: "var(--radius)" }}>
                <div className="d-flex-bteween position-relative mb-3">
                    <span className="min-title" style={{ color: task.color, backgroundColor: task.bgColor }}>{task.title}</span>
                    <DeleteTask task={task} />
                </div>
                <p className="fw-bold mt-2" style={{ fontSize: "11px", opacity: "0.8" }}>{task.desc}</p>
                <div className="d-flex-bteween" style={{ color: "var(--off-white)" }}>
                    <div className="d-flex-bteween w-50">
                        <i className="fa-solid fa-flag" style={{ fontSize: "11px" }}></i>
                        <span style={{ fontSize: "10px" }} className="fw-bold">Nov 24</span>
                        <i className="fa-solid fa-message" style={{ fontSize: "11px" }}>
                            <span style={{ fontSize: "10px" }} className="fw-bold ms-1">2</span> </i>
                        <i className="fa-solid fa-link" style={{ fontSize: "11px" }}>
                            <span style={{ fontSize: "10px" }} className="fw-bold ms-1">2</span> </i>
                    </div>
                    <img src={require("../myPhoto.PNG")} className="img-sm me-2 circle-element" style={{ width: "20px", height: "20px" }} alt="Mahmoud Nabowy" />
                </div>
            </div>
        )
    })
    return <div className="mt-3 mb-3">{renderTasks}</div>;
}

export default Tasks;