import { createSlice } from "@reduxjs/toolkit";

const toggleHideSlice = createSlice({
    initialState: [
        { newCardState: true },
        { labelsState: true },
        { projectProgressState: true },
        { fileAttachemntState: true },
        { assignTaskState: true },
        { weeklyProgressState: true },
        { buttonsState: true },
        { recentActivityState: true },
        { TasksState: true },
        { taskProgressState: true },
        { somethingWrongState: true },
        { needAttentionState: true },
        { successState: true },
        { otherCoditionState: true },
        { chatBootState: true },
    ],
    name: "toggleHideSlice",
    reducers: {
        showCard: (state, action) => {
            const updatedCard = action.payload;
            return state.map((card) => {
                if (card[updatedCard] !== undefined) {
                    console.log("Das")
                    return { ...card, [updatedCard]: true };
                }
                return card;
            });
        },
        hideCard: (state, action) => {
            const updatedCard = action.payload;
            return state.map((card) => {
                if (card[updatedCard] !== undefined) {
                    return { ...card, [updatedCard]: false };
                }
                return card;
            });
        }
    }
});

export default toggleHideSlice.reducer;

export const { showCard, hideCard } = toggleHideSlice.actions;