import { Fragment } from "react";

const SocialAbout = () => {
    return (
        <Fragment>
            <div className="cv mb-3 fw-bold"><a href="https://raw.githubusercontent.com/ProNabowy/Portfolio_img/main/_images_CV.jpg" className="text-white d-block text-center" target="_blank" download=" https://raw.githubusercontent.com/ProNabowy/Portfolio_img/main/CV_2.jpg" style={{ "fontSize": "12px" }} rel="noreferrer">DOWNLOED
                CV <i className="fa-solid fa-download"></i></a></div>
            <div className="social d-flex justify-content-center align-items-center p-3">
                <a href="https://www.facebook.com/elngmadle" target={"_blank"} className="text-white d-block" rel="noreferrer"> <i style={{ "marginLeft": "20px" }} className="fa-brands fa-facebook-f"></i></a>

                <a href="https://twitter.com/M_Nabowy" target={"_blank"} className="text-white" rel="noreferrer"><i className="ms-3 d-block fa-brands fa-twitter"></i></a>
                <a href="https://www.linkedin.com/in/mahmoud-e-nabowy-408913250/" target={"_blank"} className="text-white" rel="noreferrer"><i className="ms-3 d-block fa-brands fa-linkedin-in"></i></a>
                <a href="https://github.com/ProNabowy" target={"_blank"} className="text-white" rel="noreferrer"><i className="ms-3 d-block fa-brands fa-github"></i></a>
            </div>
        </Fragment>
    )
}

export default SocialAbout;