import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar"
import SidebarLabels from "./SidebarLabels/SidebarLabels";
import HomePageLabels from "./HomePageLabels/HomePageLabels";
import WeeklyBarLabels from "./WeeklyBarLabels/WeeklyBarLabels";
import TasksLabel from "./TasksLabel/TasksLabel";
import ChatBoot from "./ChatBoot/ChatBoot";
import { toggleFullScreenImage } from "../../utilitis/utilitis";
import { useEffect } from "react";
const Settings = () => {
    useEffect(() => {
        toggleFullScreenImage();
    }, []);
    return (
        <div>
            <Sidebar />
            <div className="d-flex-bteween flex-wrap settings" style={{ "marginLeft": "50px" }}>
                <div className="p-3 col-12 col-lg-6">
                    <Link to={"/"}><i className="fa-solid fa-arrow-left me-3"></i>Back To dashboard</Link>
                    <div>
                        <h2 className="mt-3">Settings</h2>
                        <p className="text-black-50 fw-bold" style={{ fontSize: "13px" }}>You can controal your dashbarod from this settings actions</p>
                    </div>
                    <SidebarLabels />
                    <HomePageLabels />
                    <WeeklyBarLabels />
                    <TasksLabel />
                </div>
                <ChatBoot />
            </div>
        </div>
    )
}

export default Settings;