import { useEffect } from "react";
import Header from "../Header/Header";
import Activity from "./Activity/Activity";
import AssignTask from "./AssignTask/AssignTask";
import ButtonsBox from "./ButtonsBox/ButtonsBox";
import FileAttachment from "./FileAttachment/FileAttachment";
import HomeTasks from "./HomeTasks/HomeTasks";
import Notifications from "./Notifications/Notifications";
import ProjectProgress from "./ProjectProgress/ProjectProgress";
import TaskProgress from "./TaskProgress/TaskProgress";
import WeeklyProgress from "./WeeklyProgress/WeeklyProgress";
import { matchBoxHeights, toggleFullScreenImage } from "../../utilitis/utilitis";

const Home = () => {
    useEffect(() => {
        matchBoxHeights();
    }, [document.body.clientHeight]);
    useEffect(() => {
        toggleFullScreenImage();
    }, []);
    return (
        <div>
            <Header />
            <div className="d-flex-bteween flex-wrap home-boxs">
                <div className="p-3 first-box" style={{ width: "38%", borderRadius: "var(--radius)" }}>
                    <ProjectProgress />
                    <FileAttachment />
                    <AssignTask />
                </div>
                <div className="p-3 second-box" style={{ width: "33%", borderRadius: "var(--radius)" }}>
                    <WeeklyProgress />
                    <ButtonsBox />
                    <Activity />
                </div>
                <div className="p-3 thired-box" style={{ width: "27%", borderRadius: "var(--radius)" }}>
                    <HomeTasks />
                    <TaskProgress />
                    <Notifications />
                </div>

            </div>
        </div>
    )
}

export default Home;