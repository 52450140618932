import { useState } from "react";

const CheckBox = ({ index, radius }) => {
    const [labelState, setLabelState] = useState(false);
    const toggleScale = (index) => {
        const CURRENT_ELEMENT = document.querySelector(`#label-${index}`);
        labelState ? CURRENT_ELEMENT.classList.add("scale") : CURRENT_ELEMENT.classList.remove("scale");
        setLabelState(!labelState);
    }
    return (
        <div className={`p-1 check-box ${radius && "circle-element"}`} onClick={() => toggleScale(index)}>
            <div className="checked scale d-flex-center" id={`label-${index}`} >
                <i className="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

export default CheckBox;