import Home from "../Home/Home";
import Sidebar from "../Sidebar/Sidebar";

const HomeContent = () => {
    return (
        <div className="home-page d-flex-center align-items-start flex-wrap mt-2">
            <div className="home-page-sidebar p-2">
                <Sidebar />
            </div>
            <div className="home-page-content p-2">
                <Home />
            </div>
        </div>
    )
}

export default HomeContent;