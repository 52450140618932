import { useState } from "react";
import { NavLink } from "react-router-dom";
import { searchLogic } from "../../utilitis/utilitis";

const Header = () => {
    const [userValue, setUserValue] = useState("");
    return (
        <header className={`p-3 bg-white mb-2`} style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <div className="row align-items-center">
                <div className="col-lg-4 col-md-6 header-form d-flex-center">
                    <div className="d-flex  align-items-center me-3">
                        <div className="circle-element me-2 d-flex-center fw-bolder text-white" style={{ backgroundColor: "var(--blue)", width: "30px", height: "30px" }}>T</div>
                        <span className="fw-bold" style={{ color: "var(--blue)" }}>Task</span>
                    </div>
                    <form className="position-relative" onSubmit={e => window.location.pathname === "/" ? searchLogic(e, userValue) : e.preventDefault()}>
                        <input type="search" onChange={(e) => setUserValue(e.target.value)} className="min-input" placeholder="Search everything" id="search-input" />
                        <i className="fa-solid fa-magnifying-glass search-icone"></i>
                    </form>
                </div>
                <div className="col-lg-5 col-md-6">
                    <ul className="d-flex-center m-0">
                        <li className="nav-item">
                            <NavLink className="nav-link text-black-50 me-4 fw-bold" aria-current="page" to={"/"}>Projects</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-black-50 me-4 fw-bold" aria-current="page" to={"/settings"}>Settings</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-black-50 me-4 fw-bold" aria-current="page" to={"/about"}>About</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-3 header-name d-flex-center">
                    <div>
                        <img src={require("./myPhoto.PNG")} className="img-sm circle-element me-2" alt="my image" />
                        <span className="fw-bold text-black-50" style={{ fontSize: "12px" }}>Nabowy</span>
                    </div>
                    <i className="fa-solid position-relative fa-bell ms-5 text-black-50"></i>
                </div>
            </div>
        </header>
    )
}
export default Header;