import { Fragment } from "react";
import NewCard from "./NewCard/NewCard";
import Tasks from "./Tasks/Tasks";
import Labels from "./Labels/Labels";

const Sidebar = () => {
    return (
        <Fragment>
            <NewCard />
            <Tasks />
            <Labels />
        </Fragment>
    )
}

export default Sidebar;