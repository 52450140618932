const ChatMessage = () => {
    return (
        <div className="chat-wrapper d-flex flex-column">
            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#6097ff38", borderRadius: '5px', width: 'fit-content', maxWidth: '90%' }}>
                <p style={{ fontWeight: 400, opacity: 0.7, fontSize: '12px', width: '85%' }}>Hi, i have great news. A new project is starting, I'll tell you more about it later</p>
                <span className="text-black-50 fw-bold me-2" style={{ opacity: 0.7, width: '10%', fontSize: '11px', alignSelf: 'flex-end' }}>8:29</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#6097ff38", borderRadius: '5px', width: 'fit-content', maxWidth: '90%' }}>
                <p style={{ fontWeight: 400, opacity: 0.7, fontSize: '12px', width: '85%' }}>it's a new neobank with great deposit terms</p>
                <span className="text-black-50 fw-bold me-2" style={{ opacity: 0.7, width: '10%', fontSize: '11px', alignSelf: 'flex-end' }}>8:30</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#6097ff38", borderRadius: '5px', width: 'fit-content', maxWidth: '90%' }}>
                <p style={{ fontWeight: 400, opacity: 0.7, fontSize: '12px', width: '85%' }}>
                    nabowy.work@gmail.com
                    <br />
                    https://www.nabowy.online
                </p>
                <span className="text-black-50 fw-bold me-2" style={{ opacity: 0.7, width: '10%', fontSize: '11px', alignSelf: 'flex-end' }}>8:30</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#315bb947", borderRadius: "5px", width: "fit-content", maxWidth: "90%", alignSelf: "flex-end" }}>
                <p style={{ fontWeight: 400, fontSize: "12px", width: "85%" }}>
                    Hi. wow, that's intriguing
                </p>
                <span className="text-black-50 fw-bold "
                    style={{ width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px" }}>8:31</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#315bb947", borderRadius: "5px", width: "fit-content", maxWidth: "90%", alignSelf: "flex-end" }}>
                <p style={{ fontWeight: 400, fontSize: "12px", width: "85%" }}>
                    I'm looking forward to the details
                </p>
                <span className="text-black-50 fw-bold "
                    style={{ width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px" }}>8:31</span>
            </div>

            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#315bb947", borderRadius: "5px", width: "fit-content", maxWidth: "90%" }}>
                <p style={{ fontWeight: "400", opacity: "0.2", fontSize: "12px", width: "85%" }} className="d-flex-bteween">
                    <span className="main-cricle d-flex-center mb-0 me-2" style={{ backgroundColor: "#606878", width: "25px", height: "25px" }}>
                        <i className="fa-solid fa-play text-white"></i>
                    </span>
                    <span>-||---|||---|||--|||</span>
                </p>
                <span className="text-black-50 fw-bold "
                    style={{ opacity: "0.4", width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px" }}>8:29</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ backgroundColor: "#315bb947", borderRadius: "5px", width: "fit-content", maxWidth: "90%", alignSelf: "flex-end" }}>
                <p style={{ fontWeight: "400", fontSize: "12px", width: "85%" }}>
                    Okay, I can't wait to get started
                </p>
                <span className="text-black-50 fw-bold "
                    style={{ width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px" }}>8:31</span>
            </div>
        </div >
    )
}

export default ChatMessage;