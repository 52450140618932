import { createSlice } from "@reduxjs/toolkit";

const newCardSlice = createSlice({
    initialState: [
        {
            id: 1,
            title: "Copywriting",
            desc: "Membuat copy di bagian homepage untuk memberi kesan simple dan canggih",
            color: "#b055bd",
            bgColor: "#f3dcf6"
        },
        {
            id: 2,
            title: "Ui Design",
            desc: "Membuat konsep layout untuk homepage",
            color: "var(--vivid-cerulean)",
            bgColor: "#cfecfd",
        },
        {
            id: 3,
            title: "Mustration",
            desc: "Membuat konsep ilustrasi untuk halaman homepage dan about us",
            color: "#479d72",
            bgColor: "#d7ede3"
        },
    ],
    name: "newCardSlice",
    reducers: {
        addNewCard: (state, action) => {
            state.push(action.payload)
        },
        deleteCard: (state, action) => {
            const filteredState = state.filter(card => card.id !== action.payload.id);
            // return new state
            return filteredState;
        }
    }
});

export default newCardSlice.reducer;
export const { addNewCard, deleteCard } = newCardSlice.actions;