import CardToggleInput from "../CardToggleInput/CardToggleInput";

const TasksLabel = () => {
    return (
        <div className="d-flex-bteween pb-3">
            <div>
                <h4 className="mt-3">Tasks</h4>
                <p className="text-black-50 fw-bold" style={{ fontSize: "13px" }}>You can controal your Tasks cards as you want</p>
            </div>
            <div>
                <CardToggleInput cardState="TasksState" labelText="Tasks" />
                <CardToggleInput cardState="taskProgressState" labelText="Task Progress" />
                <CardToggleInput cardState="somethingWrongState" labelText="Something Wrong" />
                <CardToggleInput cardState="needAttentionState" labelText="Need Attention" />
                <CardToggleInput cardState="successState" labelText="Success" />
                <CardToggleInput cardState="otherCoditionState" labelText="Other Codition" />
            </div>
        </div>
    )
}

export default TasksLabel;