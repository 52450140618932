import HideCard from "../../HideCard/HideCard";

const Notifications = () => {
    const notfData = [
        { cardState: "somethingWrongState", icone: "fa-solid fa-exclamation", color: "#f55641", bgColor: "#f0e4e8", title: "Something Wrong", desc: "communicate more powerfully than" },
        { cardState: "needAttentionState", icone: "fa-solid fa-exclamation", color: "var(--yellow)", bgColor: "#f3e4d2", title: "Need Attention", desc: "communicate more powerfully than" },
        { cardState: "successState", icone: "fa-solid fa-check", color: "var(--green)", bgColor: "#ddf2eb", title: "Success", desc: "communicate more powerfully than" },
        { cardState: "otherCoditionState", icone: "fa-solid fa-check", color: "var(--blue)", bgColor: "#e2f2fd", title: "Other Codition", desc: "communicate more powerfully than" },
    ];

    const renderNotifcations = notfData.map((object, index) => {
        return (
            <div key={index} className={`d-flex align-items-center mb-3 p-3 position-relative ${object.cardState}`} id={object.cardState} style={{ background: object.bgColor, color: object.color, borderRadius: "var(--radius)", border: `1px dashed ${object.color}` }}>
                <HideCard cardState={object.cardState} />
                <div className="circle-element d-flex-center text-white me-2" style={{ width: "30px", height: "30px", backgroundColor: object.color }}>
                    <i className={object.icone}></i>
                </div>
                <div style={{ fontSize: "12px" }}>
                    <h6 className="m-0">{object.title}</h6>
                    <p className="m-0" style={{ opacity: 0.7 }}>{object.desc}</p>
                </div>
            </div>
        )
    })
    return (
        <div className="position-relative" id="notifications" style={{ borderRadius: "var(--radius)" }}>
            {renderNotifcations}
        </div>
    )
}

export default Notifications;