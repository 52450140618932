import CheckBox from "./CheckBox";
import HideCard from "../../HideCard/HideCard";

const Labels = () => {
    const arrOfLabels = [
        { title: "CopyWriting" },
        { title: "Mustration" },
        { title: "UI Design" },
    ];
    const renderLabels = arrOfLabels.map((label, index) => {
        return (
            <div key={index} className="d-flex-bteween mb-2 pb-2" style={{ borderBottom: "1px solid #0a9ff821" }}>
                <div style={{ fontSize: "12px", letterSpacing: "1px" }}>
                    <i className="fa-solid fa-tag me-3">
                        <span style={{ fontSize: "9px" }} className="text-black ms-2 fw-bold">{label.title}</span>
                    </i>
                </div>
                <CheckBox index={index} />
            </div>
        )
    })
    return (
        <div className="bg-white p-3" id="labelsState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="labelsState" />
            <h6 className="fw-bold mb-3">Labels</h6>
            {renderLabels}
        </div>
    )
}
export default Labels;