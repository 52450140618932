import { NavLink } from "react-router-dom";

const Sidebar = () => {
    return (
        <div className="nav-wrapper bg-white" style={{ borderRight: "1px solid var(--main-color)" }}>
            <ul className="link-items">
                <li className="link-item">
                    <NavLink to={"/"} className="link text-black-50 nav-link fw-bold">
                        <i className="fa-solid fa-house"></i>
                    </NavLink>
                </li>
                <li className="link-item">
                    <NavLink to={'/settings'} className="link text-black-50 nav-link fw-bold">
                        <i className="fa-solid fa-gear"></i>
                    </NavLink>
                </li>
                <li className="link-item">
                    <NavLink to={'/about'} className="link text-black-50 nav-link fw-bold">
                        <i className="fa-solid fa-address-card"></i>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar;