import HideCard from "../../HideCard/HideCard";

const TaskProgress = () => {
    const tasksData = [
        { taskName: "Copywriting", color: "#c573d4", bgColor: "#f6edf8", width: "40%", date: "3/8" },
        { taskName: "illustrations", color: "var(--green)", bgColor: "#ebf5ef", width: "60%", date: "6/10" },
        { taskName: "Ui Design", color: "var(--blue)", bgColor: "#e8f6fe", width: "30%", date: "2/7" },
    ];
    const renderTasks = tasksData.map((task, index) => {
        return (
            <div key={index} className="mb-3">
                <div className="d-flex-bteween" style={{ fontSize: "12px" }}>
                    <p className="fw-bold mb-0">{task.taskName}</p>
                    <p className="fw-bold text-black-50 ">{task.date}</p>
                </div>
                <div style={{ backgroundColor: task.bgColor, borderRadius: "3px" }}>
                    <div style={{ backgroundColor: task.color, padding: "2.5px", borderRadius: "3px", width: task.width }}></div>
                </div>
            </div>
        )
    })
    return (
        <div className="position-relative p-3 mb-3 bg-white" id="taskProgressState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="taskProgressState" />
            <h6 className="fw-bold mt-2 fs-7">Task Progress</h6>
            {renderTasks}
        </div>
    )
}

export default TaskProgress;