import HideCard from "../../HideCard/HideCard";

const ButtonsBox = () => {
    return (
        <div className="bg-white p-3 mb-2" id="buttonsState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="buttonsState" />
            <button className="min-button p-3 mb-3 mt-4">Noraml Button</button>
            <button className="min-button p-3 mb-3 d-flex-center overflow-hidden" style={{ backgroundColor: "#7783ed78", color: "var(--blue-bell)" }}>
                <span className="loader"></span>
                <span>Loading Button</span></button>
            <button className="min-button p-3 mb-3" style={{ backgroundColor: "transparent", color: "var(--blue-bell)", border: "1px solid var(--blue-bell)" }}>Secondary Button</button>
        </div>
    )
}

export default ButtonsBox;