import { useDispatch, useSelector } from "react-redux";
import { addNewCard } from "../../../store/slice/newCardSlice";
import { useState } from "react";
import HintMessage from "../../HintMessage/HintMessage";
import HideCard from "../../HideCard/HideCard";

const NewCard = () => {
    const dispatch = useDispatch();
    const tasksCount = useSelector(store => store.newCard).length;
    const message = "Hey, You Can Replace The Frontend Message. Just Click On It";
    const [userValue, setUserValue] = useState("");
    const [hintMessageUi, setHintMessageUi] = useState(false);
    const handelNewTask = () => {
        const tasktitle = document.querySelector(".user-title").innerHTML;
        dispatch(addNewCard({ id: tasksCount + 1, title: tasktitle, desc: userValue, color: "var(--vivid-cerulean)", bgColor: "#cfecfd" }));
    }
    const hintMessage = () => {
        setHintMessageUi(true);
        const timeOut = setTimeout(() => {
            setHintMessageUi(false);
        }, 4000);
        return () => clearTimeout(timeOut);
    }
    return (
        <div className="position-relative p-3 bg-white" id="newCardState" style={{ borderRadius: "var(--radius)" }}>
            <HideCard cardState="newCardState" />
            <h6 className="fw-bold mt-2 fs-7">Create New Card</h6>
            <div>
                <textarea className="add-task-input p-2 mb-2" onFocus={() => hintMessage()} onChange={e => setUserValue(e.target.value)} placeholder="What is the task?"></textarea>
                <div className="d-flex-bteween pb-2 mb-3 position-relative" style={{ borderBottom: "1px solid #0a9ff821" }}>
                    <div className="d-flex-center">
                        <i className="fa-solid fa-tag me-3"></i>
                        <span className="min-title user-title" datatype="d" contentEditable={true} dangerouslySetInnerHTML={{ __html: "Frontend" }}></span>
                        {hintMessageUi && <HintMessage message={message} />}
                    </div>
                    <span className="main-cricle d-flex-center" onClick={() => hintMessage()}><i className="fa-solid fa-plus" style={{ fontSize: "10px" }}></i></span>
                </div>
                <div className="d-flex-bteween pb-2 mb-3" style={{ borderBottom: "1px solid #0a9ff821" }}>
                    <div className="d-flex-center">
                        <i className="fa-solid fa-user-plus me-3"></i>
                        <span className="d-flex-center">
                            <img src={require("../myPhoto.PNG")} className="img-sm me-2 circle-element" alt="Mahmoud Nabowy" />
                            <span className="text-black-50 fw-bold" style={{ fontSize: "12px" }}>Nabowy</span>
                        </span>
                    </div>
                    <span className="main-cricle d-flex-center"><i className="fa-solid fa-plus" style={{ fontSize: "10px" }}></i></span>
                </div>
                <button className="min-button" onClick={() => handelNewTask()}>Done</button>
            </div>
        </div>
    )
}

export default NewCard;