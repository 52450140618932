import Tasks from "./Tasks/Tasks";
import HideCard from "../../HideCard/HideCard";

const AssignTask = () => {
    return (
        <div style={{ position: "relative" }} id="assignTaskState">
            <HideCard cardState="assignTaskState" />
            <div className="bg-white p-3 mb-2" style={{ borderRadius: "var(--radius)" }}>
                <h6 className="fw-bold mt-2 text-black-50 fs-7 d-flex-bteween">
                    <div>
                        <i className="fa-solid fa-user-plus me-3"></i>
                        <span>Assign Task To </span>
                        <i className="ms-2 fa-solid fa-angle-down"></i>
                    </div>
                </h6>
            </div>
            <Tasks />
        </div>
    )
}

export default AssignTask;