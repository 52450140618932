const AboutContent = () => {
    return (
        <div className="col-lg-9 d-flex justify-content-center align-items-center flex-column" style={{ "backgroundColor": "var(--white)" }}>
            <h1 className="text-center fw-bolder m-3 text-black leRotateXZoomIn user-select-none">About Me </h1>
            <p className="text-black-50 w-75 text-center fs-6 fw-bold" style={{ "margin": "15px auto" }}>
                Hello, I'm Front-End Deveolper, I Can Build any Professional Website With High Quality, Best
                Performance,
                Better Search Engine Optimization, Any Responisve Website
            </p>
            <p className="text-black-50 w-75 text-center fs-6 fw-bold" style={{ "margin": "15px auto" }}>I've A good
                Experience
                About How To Build
                Professional
                Animation Website,
                The whole project was started as an idea of using a web demo to explain what procedural
                noise is to our clients at me
                website. After sending out the demo to some of our friends </p>
            <p className="text-black-50 w-75 text-center fs-6 fw-bold text-capitalize" style={{ "margin": "15px auto" }}>this
                website is an
                multidisciplinary
                production studio. From creative to production, we
                collaborate with creative
                Freinds and design studios to deliver compelling, real-time experiences, which go far beyond
                expectations,
                I will set all my projects here to let you see all of those
            </p>
            <p className="text-black-50 w-75 text-center fs-6 fw-bold text-capitalize" style={{ "margin": "15px auto" }}>
                i can Fixing a broken sitemap file and adding schema markup to a landing Page
                working with the editorial team to make thier content better for SEO,
                building an SEO QA process for dev team
                <br />
                Benchmarking the user experience of our landing pages vs competitors to create continual
                improvement
                <br />
                Pitching for new editorial resources dedicated to SEO goals
            </p>
        </div>
    )
}

export default AboutContent;