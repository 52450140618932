import HideCard from "../../HideCard/HideCard";

const Activity = () => {
    const recentActivity = [
        { name: "Nabowy", color: "var(--yellow)", describe: "Uploaded 3 documents on Kosep design homepage", date: "10 Aug" },
        { name: "Andrea", color: "var(--green)", describe: "Uploaded 3 documents on Kosep design homepage", date: "6 July" },
        { name: "Karen", color: "var(--blue-bell)", describe: "Uploaded 3 documents on Kosep design homepage", date: "8 Feb" },
    ];
    const renderActivity = recentActivity.map((activity, index) => {
        return (
            <div key={index} className="d-flex align-items-center mb-3">
                <div className="me-2 d-flex-center circle-element text-white" style={{ minWidth: "30px", height: "30px", backgroundColor: activity.color }}>
                    <i className="fa-solid fa-message" style={{ fontSize: "12px" }}></i>
                </div>
                <div>
                    <div style={{ fontSize: "12px" }}>
                        <span className="fw-bold">{activity.name} </span>
                        <span className="fw-bold text-black-50"> {activity.describe}</span>

                    </div>
                    <span className="text-black-50 fw-bold" style={{ fontSize: "10px" }}>{activity.date}</span>
                </div>
            </div>
        )
    })
    return (
        <div className="bg-white p-3 mb-2" id="recentActivityState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="recentActivityState" />
            <h6 className="fw-bold mb-3">Recent Activity</h6>
            {renderActivity}
        </div>
    )
}

export default Activity;