import { useEffect, useState } from "react";
import HideCard from "../../HideCard/HideCard";
const WeeklyProgress = () => {
    const [progressCount, setProgressCount] = useState("0%");
    useEffect(() => {
        const getNumber = parseInt(progressCount);
        const intervel = setInterval(() => {
            setProgressCount(`${getNumber + 1}%`);
        }, 18);
        if (getNumber == 100) clearInterval(intervel);
        return () => clearInterval(intervel);
    }, [progressCount]);
    return (
        <div className="bg-white p-3 mb-2" id="weeklyProgressState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="weeklyProgressState" />
            <h6 className="fw-bold">Weekly Progress</h6>
            <div className="fw-bold" style={{ color: "#d5d6df", fontSize: "11px" }}>
                <span>Start From Nov 7-14 2023</span>
            </div>
            <div className="d-flex-center mt-4 mb-3">
                <div className="circular position-relative">
                    <div className="inner"></div>
                    <div className="outer"></div>
                    <div className="numb center-element">
                        {progressCount}
                        <p className="text-black-50">tasks Completed</p>
                    </div>
                    <div className="circle">
                        <div className="dot">
                            <span></span>
                        </div>
                        <div className="bar left w-100 h-100">
                            <div className="progress w-100 h-100"></div>
                        </div>
                        <div className="bar right w-100 h-100">
                            <div className="progress w-100 h-100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WeeklyProgress;