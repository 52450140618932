import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteCard } from "../../../../store/slice/newCardSlice";

const DeleteTask = ({ task }) => {
    const [deleteState, setDeleteState] = useState(false);
    const dispatch = useDispatch();
    return (
        <Fragment>
            <i className="fa-solid fa-ellipsis" onClick={() => setDeleteState(!deleteState)} style={{ color: "var(--off-white)", cursor: "pointer" }}></i>
            <div className={`delete p-1 ${deleteState && "active"}`} onClick={() => dispatch(deleteCard(task))} style={{ color: task.color, backgroundColor: task.bgColor }}>
                Delete
            </div>
        </Fragment>
    )
}

export default DeleteTask;