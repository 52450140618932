import HideCard from "../../HideCard/HideCard";
import HomeTask from "./HomeTask";

const HomeTasks = () => {
    const tasksData = [
        { taskName: "Design HomePage", date: "Monday, Nov 23" },
        { taskName: "Sketsa ilustrasi", date: "Monday, Nov 6" },
        { taskName: "Onboarding Design", date: "Wednesday, Nov 15" },
    ];
    const renderTasks = tasksData.map((task, index) => {
        return <HomeTask task={task} index={index}/>
    })
    return (
        <div className="position-relative p-3 mb-3 bg-white" id="TasksState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="TasksState" />
            <h6 className="fw-bold mt-2 fs-7">Tasks</h6>
            {renderTasks}
        </div>
    )
}

export default HomeTasks;