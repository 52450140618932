import HideCard from "../../HideCard/HideCard";
import People from "./People";

const ProjectProgress = () => {
    return (
        <div className="bg-white p-3 mb-2" id="projectProgressState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="projectProgressState" />
            <h6 className="fw-bold mb-3">Start Desing Homepage</h6>
            <div className="fw-bold" style={{ color: "#d5d6df", fontSize: "11px" }}>
                <i className="fa-solid fa-flag me-2"></i>
                <span>Due Nov 24</span>
            </div>
            <p className="text-black-50 fw-bold fs-7 mt-2 mb-2" style={{ opacity: "0.7" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
            </p>
            <div className="mt-4">
                <div className="d-flex-bteween fs-7 fw-bold">
                    <h6 className="fs-7 fw-bold">Project Progress</h6>
                    <div>32%</div>
                </div>
                <div className="mt-3" style={{ backgroundColor: "#ecf7f1", borderRadius: "var(--radius)" }}>
                    <div className="w-25" style={{ borderRadius: "var(--radius)", backgroundColor: "var(--green)", padding: "2.5px" }}></div>
                </div>
                    <People />
            </div>
        </div>
    )
}

export default ProjectProgress;