import { Route, Routes } from "react-router-dom";
import Settings from "./Components/Settings/Settings";
import About from "./Components/About/About";
import PageLoader from "./Components/PageLoader/PageLoader";
import { useEffect, useState } from "react";
import HomeContent from "./Components/HomeContent/HomeContent";
const App = () => {
  const [isContentLoaded, setContentLoader] = useState(false);
  const setLoader = () => setContentLoader(true);
  useEffect(() => {
    if (!isContentLoaded) {
      document.body.style.overflowY = "hidden";
    }
  }, []);
  useEffect(() => {
    if (isContentLoaded) {
      document.body.style.overflowY = "visible";
    }
  }, [isContentLoaded]);
  return (
    <div className="App" onLoad={setLoader}>
      {!isContentLoaded && <PageLoader />}
      <Routes>
        <Route path="/" element={<HomeContent />} />
        <Route path="settings" element={<Settings />} />
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
