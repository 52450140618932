import ProfileBox from "./ProfileBox";
import ConversationInput from "./ConversationInput";
import ChatMessage from "./ChatMessage";
import HideCard from "../../HideCard/HideCard";
import { useEffect } from "react";
import { chatLogic } from "../../../utilitis/utilitis";

const ChatBoot = () => {
    useEffect(() => {
        chatLogic();
    }, []);
    return (
        <div className="chat-boot mb-1 mt-3 p-1 position-relative me-2" id="chatBootState" style={{width: "400px"}}>
            <HideCard cardState={"chatBootState"} />

            <div style={{ backgroundColor: 'var(--white)', width: '100%', borderRadius: '10px' }} className="p-2">
                <div className="chat-head mb-3 p-2 user-select-none position-relative">
                    <span>
                        <span style={{ cursor: 'pointer' }} className="me-3"><i className="fa-solid fa-video"></i></span>
                        <span style={{ cursor: 'pointer' }} className="me-3"><i className="fa-solid fa-phone"></i></span>
                    </span>
                    <div style={{ cursor: 'pointer', position: "absolute", right: 0, top: "0px", width: "40px", height: "30px", zIndex: Number.MAX_SAFE_INTEGER }} className="d-flex-center bg-white"><i className="fa-solid fa-ellipsis-vertical"></i></div>
                </div>
                <ProfileBox />
                <div className="full-chat pb-3" style={{ maxHeight: "532px", overflowY: "auto", overflowX: "hidden" }}>
                    <h6 className="text-center fw-bold position-relative mb-3">Full Chat</h6>
                    <ChatMessage />
                </div>
                <ConversationInput />
            </div>
        </div >
    )
}

export default ChatBoot;