import HideCard from "../../HideCard/HideCard";

const FileAttachment = () => {
    return (
        <div className="bg-white p-3 mb-2" id="fileAttachemntState" style={{ borderRadius: "var(--radius)", position: "relative" }}>
            <HideCard cardState="fileAttachemntState" />
            <h6 className="fw-bold mb-3">File Attachemnt</h6>
            <div className="me-2 d-flex-bteween mb-4">
                <i className="fa-solid fa-file-pdf fs-2 me-3" style={{ color: "#f8563f" }}></i>
                <div className="w-100">
                    <div className="d-flex-bteween mb-1 fw-bold">
                        <span style={{ fontSize: "12px" }}>User-Journey.pdf</span>
                        <span style={{ fontSize: "12px" }}>4.5MB</span>
                        <span style={{ fontSize: "12px" }}>Cancel</span>
                    </div>
                    <div style={{ backgroundColor: "#dff0fa", borderRadius: "var(--radius)" }}>
                        <div className="w-25" style={{ borderRadius: "var(--radius)", backgroundColor: "var(--blue)", padding: "2.5px" }}></div>
                    </div>
                </div>
            </div>
            <div className="me-2 d-flex-bteween">
                <i className="fa-solid fa-file-pdf fs-2 me-3" style={{ color: "var(--green)" }}></i>
                <div className="w-100">
                    <div className="d-flex-bteween fw-bold">
                        <span style={{ fontSize: "12px" }}>User-Journey.pdf</span>
                        <span style={{ fontSize: "12px" }}>4.5MB</span>
                        <span style={{ fontSize: "12px", color: "var(--blue)" }}>Download</span>
                    </div>
                    <p className="fw-bold text-black-50 m-0" style={{ fontSize: "10px" }}>Mahmoud Nabowy</p>
                </div>
            </div>
        </div>
    )
}

export default FileAttachment;